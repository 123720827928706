import React from 'react';
import Chart from 'react-apexcharts';
import { Row, Col, Typography, Table, Empty, Collapse, Modal, Space } from 'antd';
import {  } from '@ant-design/icons';

const { Title, Link } = Typography;
const { Panel } = Collapse;

const Global = props => {

    
    const columns = [
        {
            title: 'Название',
            dataIndex: 'text',
            key: 'text',
            width: 100,
            sorter: (a, b) => a.text.localeCompare(b.text),
        },
        {
            title: 'Адрес',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Link target="_blank" rel="noopener noreferrer" href={`https://${text}`}>{text}</Link>,
            width: 100,
            sorter: (a, b) => a.name.localeCompare(b.name),
          }
    ];

    const columns6 = [
        {
            title: 'Поисковой запрос',
            dataIndex: 'dimensions',
            key: 'dimensions',
            width: 100,
            render: (text) => text[1]['name'],
        },{
            title: 'Поисковая система',
            dataIndex: 'dimensions',
            key: 'dimensions2',
            width: 100,
            render: (text) => text[0]['name'],
        },{
            title: 'Количество визитов',
            dataIndex: 'metrics',
            key: 'metrics',
            width: 100,
            render: (text) => text[0],
        }
    ];

    function opensite(site) {
        props.panel('site_view');
        props.api({ 'method':'site_get', 'site':`${site}` },false);
    }

    return (
        <React.Fragment>
            <Modal key="m12" mask={false} width="100%" zIndex="900" title="Просмотр сайта" visible={props.state.panel.indexOf( 'site_view' ) != -1 } onOk={() => props.panel('site_view')} onCancel={() => props.panel('site_view')} footer={null}>
                {!props.state.moreload ? (
                    <React.Fragment>
                        {1 in props.state.site_get &&
                            <>
                                <Col span={24} style={{padding:20}} >
                                    <Collapse defaultActiveKey={['1']} ghost accordion>
                                        <Panel header="Визиты за последний месяц" key="1">
                                            <Chart options={props.state.site_get[1][0].options} series={props.state.site_get[1][0].series1} type="area" height={350} />
                                        </Panel>
                                        <Panel header="Просмотры за последний месяц" key="2">
                                            <Chart options={props.state.site_get[1][0].options} series={props.state.site_get[1][0].series2} type="area" height={350} />
                                        </Panel>
                                        <Panel header="Посетители за последний месяц" key="3">
                                            <Chart options={props.state.site_get[1][0].options} series={props.state.site_get[1][0].series3} type="area" height={350} />
                                        </Panel>
                                        <Panel header="Отказы % за последний месяц" key="4">
                                            <Chart options={props.state.site_get[1][0].options} series={props.state.site_get[1][0].series4} type="area" height={350} />
                                        </Panel>
                                        <Panel header="Глубина просмотра за последний месяц" key="5">
                                            <Chart options={props.state.site_get[1][0].options} series={props.state.site_get[1][0].series5} type="area" height={350} />
                                        </Panel>
                                        <Panel header="Время на сайте за последний месяц" key="6">
                                            <Chart options={props.state.site_get[1][0].options} series={props.state.site_get[1][0].series6} type="area" height={350} />
                                        </Panel>
                                        <Panel header="Поисковые запросы за последний месяц" key="7">
                                            <Table locale={{ emptyText: <Empty description="Список пуст" />}} className="metrika" dataSource={props.state.site_get[1][2].data} scroll={{ x: 500, y: 500 }} rowKey="table" columns={columns6}></Table>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </>
                        }
                    </React.Fragment>
                ) : (
                    <div className="fcenter">
                        <Space direction="vertical">
                            <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                            <div className="fcenter">
                                Получение информации
                            </div>
                        </Space>
                    </div>
                )}
            </Modal>
            <Row style={{flexDirection:'column'}} className="column900 wowload">
                <Col span={24} style={{padding:20,marginTop:20}} className="shadowscreen" >
                    <Table 
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: event => { opensite(record.id) }
                            };
                        }} 
                        pagination={{ pageSize: 30 }}
                        locale={{ emptyText: <Empty description="Список пуст" />}} dataSource={props.state.sites} scroll={{ x: 500 }} rowKey="table2" columns={columns}>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;